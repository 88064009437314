<template>
	<div>
		<div class="flex items-center justify-center flex-wrap">
			<div class="max-w-screen-lg w-full relative" style='min-height:75vh' >
				<div v-if="!agendamentoCriado">
					<selecionar-estabelecimento v-if="agendamento.estabelecimento == null"></selecionar-estabelecimento>
					<selecionar-horario v-else></selecionar-horario>
				</div>

				<div v-else>
					<selecionar-paciente></selecionar-paciente>
				</div>
			</div>
		</div>
	</div>

</template>

<style lang="scss" scoped>

</style>

<script>
import SelecionarEstabelecimento from './Etapas/EstabelecimentoConsulta'
import SelecionarHorario from './Etapas/HorarioConsulta'
import SelecionarPaciente from './Etapas/Paciente'

export default {
	components: { SelecionarEstabelecimento, SelecionarHorario, SelecionarPaciente },
    data () {
        return {
            agendamento: {
                ie_tipo_agenda: 'C',
                ie_telemedicina: false,
                ie_hora_marcada: null,
                especialista: null,
                especialidade: { id: 57 },
                agenda: null,
                estabelecimento: null,
                horario: null,
                paciente: null
            },
            agendamentoCriado: false
        }
    },
}
</script>